import React, { FC } from 'react';
import { SocialLinksProps } from './models';
import './SocialLinks.scss';

const SocialLinks: FC<SocialLinksProps> = ({ links }) => (
  <nav>
    <ul className="social-links">
      {links.map(({ properties }) => {
        const {
          name,
          url: [linkItem],
        } = properties;

        return (
          <li key={name} className="social-links__element">
            <a href={linkItem.url} target="_blank" rel="noreferrer noopener" aria-label={name}>
              <span className="accessibility-hidden">{name}</span>
              <span className={`social-icon social-icon__${name}`} />
            </a>
          </li>
        );
      })}
    </ul>
  </nav>
);

export default SocialLinks;
