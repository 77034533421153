import React, { FC, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Nav from 'react-bootstrap/Nav';
import Logo from 'common/Logo';
import HeaderDesktopSearchModal from 'components/Header/HeaderDesktopSearchModal';
import SearchBar from 'common/SearchBar';
import Button from 'react-bootstrap/Button';
import { HeaderNavQuery } from './models';

import './HeaderNav.scss';
import HeaderNavDropdown from './HeaderNavDropdown';

const HeaderNav: FC = () => {
  const {
    navigation: { navigation, headerLinks },
    brandSettings: { brandLogo },
  }: HeaderNavQuery = useStaticQuery(
    graphql`
      fragment navItemFragment on TNavigationItem {
        label
        url {
          url
          name
        }
        mainUrl {
          url
        }
      }
      query {
        brandSettings {
          brandLogo {
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED)
            }
          }
        }
        navigation {
          navigation {
            properties {
              ...navItemFragment
            }
          }
          headerLinks {
            properties {
              ...navItemFragment
            }
          }
        }
      }
    `
  );

  const [isOpened, setIsOpened] = useState(false);

  const setSearchModalOpen = () => {
    setIsOpened(true);
  };

  const renderLinks = (links) =>
    links.map(({ properties }) => {
      const { url, label } = properties;

      return url?.length > 1 ? (
        <HeaderNavDropdown key={`HeaderNavDropdown-${label}`} dropdown={properties} />
      ) : (
        <Nav.Link href={`${url[0].url}`} key={`HeaderLink-${label}`}>
          {label}
        </Nav.Link>
      );
    });

  return (
    <Nav data-testid="header" className="header__nav-content">
      <div className="header__links-wrapper header__links-wrapper--left">
        <div className="header__main-nav-links">{renderLinks(navigation)}</div>
      </div>
      <Logo brandLogo={brandLogo} brandName="logo" />
      <div className="header__links-wrapper header__links-wrapper--right">
        <div className="header__nav-links">{renderLinks(headerLinks)}</div>
        <div className="header__nav-links header__nav-search-wrapper">
          <Button
            className="header__nav-search-icon"
            type="button"
            onClick={setSearchModalOpen}
            aria-label="Search"
          />
          {isOpened ? (
            <HeaderDesktopSearchModal onInteraction={() => setIsOpened(false)} isOpen={isOpened} />
          ) : null}
          <SearchBar className="header__searchbar mobile" text="Search" />
        </div>
      </div>
    </Nav>
  );
};

export default HeaderNav;
