import React, { FC } from 'react';
import { GatsbyImage, getImage, withArtDirection, IGatsbyImageData } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import { ImageProps, ImageBreakpoint, ResponsiveImage, ImageDataLike } from './models';
import { getArtDirectedImages } from './utils';

const defaultBreakpoints: ImageBreakpoint[] = [
  { alias: 'mobile', media: '(max-width: 500px)' },
  { alias: 'tablet', media: '(max-width: 1024px)' },
];

const Image: FC<ImageProps> = ({
  imageData,
  alt,
  breakpoints = defaultBreakpoints,
  ...restProps
}) => {
  const isArtDirected = Object.keys(imageData).includes('desktop');

  const mainImage = isArtDirected
    ? getImage((imageData as ResponsiveImage).desktop)
    : getImage(imageData as ImageDataLike);

  const images = withArtDirection(
    mainImage as IGatsbyImageData,
    getArtDirectedImages(breakpoints, imageData as ResponsiveImage)
  );

  return <GatsbyImage image={images} alt={alt} {...restProps} />;
};

export const createImageWithBreakpoints = (breakpoints: ImageBreakpoint[]): FC<ImageProps> => (
  props: ImageProps
) => <Image {...props} breakpoints={breakpoints} />;

export const query = graphql`
  fragment ImageFragment on ResponsiveImage {
    desktop {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
    tablet {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
    mobile {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 90)
      }
    }
  }
`;

export default Image;
