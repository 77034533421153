import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';
import { LogoProps } from './models';

const Logo: FC<LogoProps> = ({ brandLogo, brandName }) => (
  <strong className="logo">
    <Link to="/">
      <Image imageData={brandLogo} alt={brandName} className="logo__img" />
    </Link>
  </strong>
);

export default Logo;
