import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { Col, Container, Row } from 'react-bootstrap';
import Image from 'common/Image';
import FooterNavbar from './FooterNavbar';
import SocialLinks from './SocialLinks';
import { FooterQuery } from './models';
import './styles.scss';

export const Footer = () => {
  const {
    footer: { logo, navbar, navbarSecond, navbarThird, navbarFourth, copyright, socialLinks },
  }: FooterQuery = useStaticQuery(graphql`
    fragment navbarProperties on FooterNavbar {
      properties {
        label
        url {
          url
          target
        }
      }
    }
    query {
      footer {
        copyright
        logo {
          childImageSharp {
            gatsbyImageData(width: 100, placeholder: BLURRED)
          }
        }
        socialLinks {
          properties {
            name
            url {
              url
            }
          }
        }
        navbar {
          ...navbarProperties
        }
        navbarSecond {
          ...navbarProperties
        }
        navbarThird {
          ...navbarProperties
        }
        navbarFourth {
          ...navbarProperties
        }
      }
    }
  `);

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col xs={12} lg={6}>
            <div className="footer__logo">
              <Image imageData={logo} alt="logo" className="footer__img" />
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <SocialLinks links={socialLinks} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} lg={3}>
            <FooterNavbar navbar={navbar} />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <FooterNavbar navbar={navbarSecond} />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <FooterNavbar navbar={navbarThird} />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <FooterNavbar navbar={navbarFourth} />
          </Col>
        </Row>
        <p className="footer__copyright">{copyright}</p>
      </Container>
    </footer>
  );
};

export default Footer;
