import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import useScreenRecognition from 'hooks/useScreenRecognition';
import Footer from 'components/Footer/Footer';
import Header from 'components/Header';
import Seo from 'common/Seo';
import Breadcrumbs from 'common/Breadcrumbs';
import './Layout.scss';

import { LayoutProps, LayoutQuery } from './models';

const Layout: FC<LayoutProps> = ({
  breadcrumbs = [],
  children,
  headerTransparent,
  className,
  seo,
}) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const { brandSettings, siteSettings }: LayoutQuery = useStaticQuery(graphql`
    {
      brandSettings {
        brandName
        brandLogo {
          childImageSharp {
            gatsbyImageData(width: 100, placeholder: BLURRED)
          }
        }
      }
      siteSettings {
        siteUrl
        siteName
        lang
      }
    }
  `);

  const { isMobile } = useScreenRecognition();

  return (
    <div className={CSSclassNames}>
      <Seo
        seo={seo}
        brandSettings={brandSettings}
        siteSettings={siteSettings}
        breadcrumbs={breadcrumbs}
      />

      <link rel="preconnect" href={process.env.GATSBY_IMAGE_CLOUD_SERVER} />

      <Header />
      {breadcrumbs?.length && !isMobile ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
      <main id="main" className="layout__main">
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
