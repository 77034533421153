import React, { FC } from 'react';

import { FooterNavbarProps } from './models';
import './FooterNavbar.scss';

const FooterNavbar: FC<FooterNavbarProps> = ({ navbar }) => (
  <nav>
    <ul className="footer-navbar">
      {navbar.map(({ properties }) => {
        const { label, url } = properties;

        return (
          <li className="footer-navbar__link" data-testid="footer-navbar-item" key={label}>
            <a href={url[0].url} aria-label={label} target={url[0].target}>
              {label}
            </a>
          </li>
        );
      })}
    </ul>
  </nav>
);

export default FooterNavbar;
