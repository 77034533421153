import React, { FC, useState } from 'react';
import { navigate } from 'gatsby';

import { NavDropdown } from 'react-bootstrap';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { HeaderNavDropdownProps } from './models';

const HeaderNavDropdown: FC<HeaderNavDropdownProps> = ({
  dropdown: { label, url: urls, mainUrl },
}) => {
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const { isMobile } = useScreenRecognition();

  const showDropdown = () => {
    setTimeout(() => {
      setToggleDropdown(true);
    }, 10);
  };

  const hideDropdown = () => {
    setTimeout(() => {
      setToggleDropdown(false);
    }, 10);
  };

  const handleNavigation = () => (isMobile ? null : navigate(mainUrl[0].url));

  return (
    <NavDropdown
      id={`dropdown-${label}`}
      className="header__nav-dropdown"
      title={label}
      data-testid="Header Nav Dropdown"
      show={toggleDropdown}
      onMouseEnter={showDropdown}
      onMouseLeave={hideDropdown}
      onClick={handleNavigation}
    >
      {urls.map(({ url, name }) => (
        <NavDropdown.Item
          href={url}
          className="header__nav-dropdown-item"
          key={name}
          data-testid={name}
        >
          {name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default HeaderNavDropdown;
