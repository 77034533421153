import React, { useState, useCallback } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Navbar from 'react-bootstrap/Navbar';

import useBodyRef from 'hooks/useBodyRef';
import Logo from 'common/Logo';
import HeaderNav from 'components/Header/HeaderNav';
import { HeaderProps } from './models';

import { BODY_CLASS_NAME_MOBILE_MENU } from './constants';

import './Header.scss';

const Header = () => {
  const {
    brandSettings: { brandLogo },
  }: HeaderProps = useStaticQuery(
    graphql`
      query {
        brandSettings {
          brandLogo {
            childImageSharp {
              gatsbyImageData(width: 100, placeholder: BLURRED)
            }
          }
        }
      }
    `
  );
  const bodyTag = useBodyRef();

  const [isMenuToggled, setMenuToggled] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const handleToggle = useCallback(() => {
    if (bodyTag) {
      isMenuToggled
        ? bodyTag.classList.remove(BODY_CLASS_NAME_MOBILE_MENU)
        : bodyTag.classList.add(BODY_CLASS_NAME_MOBILE_MENU);
      setMenuToggled(!isMenuToggled);
      setIsTransparent(!isTransparent);
    }
  }, [isMenuToggled, bodyTag]);

  return (
    <header className="header">
      <Navbar
        className="header__nav-wrapper"
        onToggle={handleToggle}
        expand="lg"
        expanded={isMenuToggled}
      >
        <div className="header__mobile-logo">
          <Logo brandLogo={brandLogo} brandName="mobile-logo" />
        </div>
        <div className="header__nav-holder">
          <Navbar.Toggle className="header__nav-toggler" aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse className="header__nav" id="basic-navbar-nav">
          <HeaderNav />
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
