import { useState, useEffect } from 'react';
import { TABLET_BREAKPOINT, DESKTOP_BREAKPOINT } from 'utils/constants';
import { UseScreenRecognitionHook } from './model';

const useScreenRecognition: UseScreenRecognitionHook = () => {
  const [isMobile, setIsMobile] = useState<null | boolean>(null);
  const [isTablet, setIsTablet] = useState<null | boolean>(null);
  const [isDesktop, setIsDesktop] = useState<null | boolean>(null);

  const handleResize = () => {
    const width = window.innerWidth;

    setIsMobile(width < TABLET_BREAKPOINT);
    setIsTablet(width >= TABLET_BREAKPOINT && width < DESKTOP_BREAKPOINT);
    setIsDesktop(width >= DESKTOP_BREAKPOINT);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useScreenRecognition;
