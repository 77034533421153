import React, { FC } from 'react';

import BreadcrumbItem from 'common/Breadcrumbs/BreadcrumbItem';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Breadcrumbs.scss';
import { BreadcrumbsProps } from './models';

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => (
  <Container className="breadcrumbs" data-testid="breadcrumbs">
    <Row>
      <Col>
        {breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbItem
            key={breadcrumb.crumbLabel}
            {...breadcrumb}
            current={index === breadcrumbs.length - 1}
          />
        ))}
      </Col>
    </Row>
  </Container>
);

export default Breadcrumbs;

export const query = graphql`
  fragment FragmentAllPageSite on SitePageConnection {
    nodes {
      context {
        breadcrumb {
          location
          crumbs {
            pathname
            crumbLabel
          }
        }
        areBreadcrumbsDisplayed
        breadcrumbName
      }
    }
  }
`;
