import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

import { SeoProps } from './models';

const Seo: FC<SeoProps> = ({ siteSettings, brandSettings, lang, seo, breadcrumbs }) => {
  const { seoMetaDescription, seoMetaTitle, seoMetaKeywords } = seo || {};
  const siteTitle = siteSettings.siteName || '';
  const titleTemplate = seoMetaTitle === siteTitle ? '%s' : `%s | ${siteTitle}`;
  const siteAuthor = brandSettings.brandName;
  const siteLang = lang || siteSettings.lang;
  const meta = [
    {
      name: 'description',
      content: seoMetaDescription,
    },
    {
      property: 'og:title',
      content: seoMetaTitle,
    },
    {
      property: 'og:description',
      content: seoMetaDescription,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
    {
      name: 'twitter:creator',
      content: siteAuthor,
    },
    {
      name: 'twitter:title',
      content: seoMetaTitle,
    },
    {
      name: 'twitter:description',
      content: seoMetaDescription,
    },
    ...(seoMetaKeywords?.length
      ? [
          {
            name: 'keywords',
            content: seoMetaKeywords.join(', '),
          },
        ]
      : []),
  ];

  const path = breadcrumbs[breadcrumbs.length - 1];
  const link = [
    {
      rel: 'alternate',
      href: path ? `${siteSettings.siteUrl}${path.pathname.slice(1)}/` : `${siteSettings.siteUrl}`,
      hreflang: siteLang,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{ lang: siteLang }}
      title={seoMetaTitle}
      {...{ titleTemplate, meta, link }}
    />
  );
};

export const query = graphql`
  fragment SeoFragment on SeoPageData {
    seoMetaDescription
    seoMetaKeywords
    seoMetaTitle
    seoNoIndex
  }
`;

export default Seo;
