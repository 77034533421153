import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import { BreadcrumbItemProps } from './models';
import './BreadcrumbItem.scss';

const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ current, pathname, crumbLabel }) => (
  <>
    <Link
      className={classNames('breadcrumb-item', current && 'breadcrumb-item--current')}
      to={pathname}
    >
      {crumbLabel}
    </Link>
    {!current ? (
      <span className="breadcrumb-item__icon" aria-hidden="true" aria-label="icon" />
    ) : null}
  </>
);

export default BreadcrumbItem;
