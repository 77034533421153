import React, { FC, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import SearchBar from 'common/SearchBar';
import { HeaderDesktopSearchModalProps } from './models';

import './HeaderDesktopSearchModal.scss';

const HeaderDesktopSearchModal: FC<HeaderDesktopSearchModalProps> = ({ isOpen, onInteraction }) => {
  const [delayedClose, setDelayedClose] = useState('close');
  const ref: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const closeWithDelay = async () => {
    await setDelayedClose('animate-close');
    await timeout(500);
    await onInteraction();
  };

  useEffect(() => {
    const closeOnClickOutside = async (event) =>
      !ref?.current?.contains(event.target) && closeWithDelay();

    const closeOnScroll = async () => closeWithDelay();

    window.addEventListener('click', closeOnClickOutside);
    window.addEventListener('scroll', closeOnScroll);

    return () => {
      window.removeEventListener('click', closeOnClickOutside);
      window.removeEventListener('scroll', closeOnScroll);
    };
  }, []);

  return (
    <div
      ref={ref}
      data-testid="searchModal"
      id="searchModal"
      className={classNames('header-desktop-search-modal', isOpen && 'open', delayedClose)}
    >
      <SearchBar className="header__searchbar" text="Search" isDesktopNav />
    </div>
  );
};

export default HeaderDesktopSearchModal;
