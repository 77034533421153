import React, { FC } from 'react';

import { SectionTitleProps } from './models';

import './SectionTitle.scss';

const SectionTitle: FC<SectionTitleProps> = ({ text, isMainTitle, customTitle }) =>
  isMainTitle ? (
    <h1 className="section-title">{customTitle || text}</h1>
  ) : (
    <h3 className="section-title">{customTitle || text}</h3>
  );

export default SectionTitle;
