import React, { FC, useState, useEffect } from 'react';

import classNames from 'classnames';
import { useLocation } from '@reach/router';

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';

import { isBrowser } from 'utils/browser';
import getQueryParamByName from 'utils/getQueryParamByName';
import { SearchBarProps } from './models';
import './SearchBar.scss';
import { navigateToSearchPage } from './utils';

const SearchBar: FC<SearchBarProps> = ({ className, showInput = true, text, isDesktopNav }) => {
  const location = useLocation();
  const searchQuery = isBrowser() && getQueryParamByName('q');
  const [searchText, setSearchText] = useState(searchQuery || '');

  useEffect(() => {
    if (searchQuery && searchQuery !== searchText) {
      setSearchText(searchQuery);
    }
    if (!searchQuery && searchQuery !== searchText) {
      setSearchText('');
    }
  }, [location]);

  const onFormSubmit = (e) => {
    navigateToSearchPage(e, searchText);
  };

  return (
    <div className={classNames(className, 'search-bar', { 'search-bar--hidden': !showInput })}>
      <Form inline onSubmit={onFormSubmit}>
        <Form.Label htmlFor="inlineFormInputSearch" srOnly>
          {text}
        </Form.Label>
        <InputGroup className="search-bar__input-group">
          <Form.Control
            className="search-bar__input"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            id="inlineFormInputSearch"
            placeholder={text}
          />
          <InputGroup.Append>
            <Button
              type="submit"
              className={classNames(
                'search-bar__button',
                isDesktopNav && 'search-bar__button--desktop-nav'
              )}
              aria-label="Search"
            >
              {isDesktopNav ? (
                <span className="search-bar__text">search</span>
              ) : (
                <span className="icon-search search-bar__icon" />
              )}
            </Button>
          </InputGroup.Append>
        </InputGroup>
      </Form>
    </div>
  );
};

export default SearchBar;
